import { URLS } from 'common/constants';
import { changeRoute } from 'common/utils';
import { CustomActionsRegistry } from 'common/lib/registries';
import { ErrorCodes, ErrorHandlers } from '../enums';
import { BasicErrorSchemaType } from '../types';
import { CustomActionsEnum, CustomVariableEnum } from '../../../enums';

const basicErrorsSchema: BasicErrorSchemaType = new Map([
  [
    ErrorCodes.CartItemUnavailableOptionQuantityErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'cartItem.optionsQuantityOutOfRange.description',
    },
  ],
  [
    ErrorCodes.CartItemOptionUnavailableInBranchErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'cartItem.optionUnavailableInBranch.description',
    },
  ],
  [
    ErrorCodes.CartItemInsufficientStockErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'cartItem.insufficientStock.description',
      errorAction: CustomActionsEnum.MutateCartDetails,
    },
  ],
  [
    ErrorCodes.CartItemFulfillmentModeNotValidErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'cartItem.fulfillmentModeNotValid.description',
    },
  ],
  [
    ErrorCodes.CartItemItemUnavailableErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'cartItem.itemUnavailable.stock.description',
      errorAction: CustomActionsEnum.MutateCartDetails,
    },
  ],
  [
    ErrorCodes.CheckOutGenerateOrderNumberErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'checkout.generateOrderNumber.description',
    },
  ],
  [
    ErrorCodes.CheckOutRefetchTheCart,
    {
      errorType: ErrorHandlers.onErrorAction,
      errorAction: CustomActionsEnum.RemoveCartItemsOutOfSync,
    },
  ],
  [
    ErrorCodes.CheckOutOrderItemUnavailableErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'checkout.itemUnavailable.description',
      errorAction: CustomActionsEnum.MutateCartDetails,
    },
  ],
  [
    ErrorCodes.CheckOutInsufficientStockForOrderErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'checkout.insufficientStock.description',
      errorAction: CustomActionsEnum.MutateCartDetails,
    },
  ],
  [
    ErrorCodes.CheckOutFulfillmentModeNotValidForOrderErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'checkout.fulfillmentModeNotValid.description',
    },
  ],
  [
    ErrorCodes.CheckOutOptionUnavailableInOrderBranchErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'checkout.optionUnavailableInBranch.description',
    },
  ],
  [
    ErrorCodes.CheckOutUnavailableOptionQuantityForOrderErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'checkout.optionsQuantityOutOfRange.description',
    },
  ],
  [
    ErrorCodes.CheckOutInvalidPaymentMethodErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.invalidPaymentMethod.title',
      errorDescription: 'checkout.invalidPaymentMethod.description',
      confirmText: 'checkout.invalidPaymentMethod.confirm',
      shouldShowCloseIcon: false,
      errorAction: CustomActionsEnum.UpdatePaymentMethods,
    },
  ],
  [
    ErrorCodes.CheckOutStoreNotPublishedErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.storeNoteFound.title',
      errorDescription: 'checkout.storeNoteFound.description',
      confirmText: 'checkout.storeNoteFound.confirm',
      onConfirm: () => window.location.reload(),
      shouldShowCloseIcon: true,
    },
  ],
  [
    ErrorCodes.CheckOutCartNotFoundErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'checkout.cartNotFound.description',
      errorAction: CustomActionsEnum.RemoveCartId,
      onConfirm: () => changeRoute(URLS.HOME),
    },
  ],
  [
    ErrorCodes.CheckOutBranchNotFoundErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.branchNotFound.title',
      errorDescription: 'checkout.branchNotFound.description',
      confirmText: 'checkout.branchNotFound.confirm',
      onConfirm: () =>
        changeRoute({
          pathname: URLS.ORDER_MODE,
          query: { mode: CustomActionsRegistry.getCustomVariable(CustomVariableEnum.OrderMode) },
        }),
      shouldShowCloseIcon: false,
      errorAction: CustomActionsEnum.UpdateSelectedBranchDetails,
    },
  ],
  [
    ErrorCodes.CheckOutModeNotSupportedByBranchErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.modeNotSupportedByBranch.title',
      errorDescription: 'checkout.modeNotSupportedByBranch.description',
      confirmText: 'checkout.modeNotSupportedByBranch.confirm',
      onConfirm: () =>
        changeRoute({
          pathname: URLS.ORDER_MODE,
          query: { mode: CustomActionsRegistry.getCustomVariable(CustomVariableEnum.OrderMode) },
        }),
      shouldShowCloseIcon: false,
      errorAction: CustomActionsEnum.UpdateSelectedBranchDetails,
    },
  ],
  [
    ErrorCodes.CheckOutBranchIsBusyErrorCode,
    {
      errorType: ErrorHandlers.onErrorAction,
      errorAction: CustomActionsEnum.RefetchBranchDetails,
    },
  ],
  [
    ErrorCodes.CheckOutAddressAreaNotMatchingCartAreaErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.addressAreaNotMatchingCartArea.title',
      errorDescription: 'checkout.addressAreaNotMatchingCartArea.description',
      confirmText: 'checkout.addressAreaNotMatchingCartArea.confirm',
      onConfirm: () => changeRoute(URLS.ADDRESS_BOOK),
      shouldShowCloseIcon: false,
      errorAction: CustomActionsEnum.UpdateUserAddresses,
    },
  ],
  [
    ErrorCodes.CheckOutAddressWithNoAreaErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.addressWithNoArea.title',
      errorDescription: 'checkout.addressWithNoArea.description',
      confirmText: 'checkout.addressWithNoArea.confirm',
      onConfirm: () => changeRoute(URLS.ADDRESS_BOOK),
      shouldShowCloseIcon: false,
      errorAction: CustomActionsEnum.UpdateUserAddresses,
    },
  ],
  [
    ErrorCodes.CheckOutStoreCannotDeliverToYourAreaErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.storeCannotDeliverToYourArea.title',
      errorDescription: 'checkout.storeCannotDeliverToYourArea.description',
      confirmText: 'checkout.storeCannotDeliverToYourArea.confirm',
      onConfirm: () => changeRoute(URLS.ADDRESS_BOOK),
      shouldShowCloseIcon: false,
      errorAction: CustomActionsEnum.UpdateUserAddresses,
    },
  ],
  [
    ErrorCodes.CheckOutOrderAmountIsZeroErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.orderAmountIsZero.title',
      errorDescription: 'checkout.orderAmountIsZero.description',
      confirmText: 'checkout.orderAmountIsZero.confirm',
      onConfirm: () => changeRoute(URLS.HOME),
      shouldShowCloseIcon: false,
    },
  ],
  [
    ErrorCodes.CheckOutOrderAmountIsLessThanRequiredErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'checkout.orderAmountIsLessThanRequired.description',
    },
  ],
  [
    ErrorCodes.CheckOutBranchIsOutOfHoursErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.branchOutOfHours.title',
      errorDescription: 'checkout.branchOutOfHours.description',
      confirmText: 'checkout.branchOutOfHours.confirm',
      onConfirm: () =>
        changeRoute({
          pathname: URLS.ORDER_MODE,
          query: { mode: CustomActionsRegistry.getCustomVariable(CustomVariableEnum.OrderMode) },
        }),
      shouldShowCloseIcon: true,
      errorAction: CustomActionsEnum.RefetchModeDetails,
    },
  ],
  [
    ErrorCodes.CheckOutBranchIsYetToOpenErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.branchYetToOpen.title',
      errorDescription: 'checkout.branchYetToOpen.description',
      confirmText: 'checkout.branchYetToOpen.confirm',
      onConfirm: () =>
        changeRoute({
          pathname: URLS.ORDER_MODE,
          query: { mode: CustomActionsRegistry.getCustomVariable(CustomVariableEnum.OrderMode) },
        }),
      shouldShowCloseIcon: true,
      errorAction: CustomActionsEnum.RefetchModeDetails,
    },
  ],
  [
    ErrorCodes.CheckOutBranchIsOffTodayErrorCode,
    {
      errorType: ErrorHandlers.onErrorAction,
      errorAction: CustomActionsEnum.RefetchModeDetails,
    },
  ],
  [
    ErrorCodes.CheckOutCashNotAvailableErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.cashNotAvailable.title',
      errorDescription: 'checkout.cashNotAvailable.description',
      confirmText: 'checkout.cashNotAvailable.confirm',
      shouldShowCloseIcon: false,
      errorAction: CustomActionsEnum.UpdatePaymentMethods,
    },
  ],
  [
    ErrorCodes.CheckOutCardOnDeliveryNotAvailableErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.cardOnDeliveryNotAvailable.title',
      errorDescription: 'checkout.cardOnDeliveryNotAvailable.description',
      confirmText: 'checkout.cardOnDeliveryNotAvailable.confirm',
      shouldShowCloseIcon: false,
      errorAction: CustomActionsEnum.UpdatePaymentMethods,
    },
  ],
  [
    ErrorCodes.CheckOutPaymentMethodNotAvailableErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.paymentMethodNotAvailable.title',
      errorDescription: 'checkout.paymentMethodNotAvailable.description',
      confirmText: 'checkout.paymentMethodNotAvailable.confirm',
      shouldShowCloseIcon: false,
      errorAction: CustomActionsEnum.UpdatePaymentMethods,
    },
  ],
  [
    ErrorCodes.CheckOutCannotUsePaymentMethodErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.cannotUsePaymentMethod.title',
      errorDescription: 'checkout.cannotUsePaymentMethod.description',
      confirmText: 'checkout.cannotUsePaymentMethod.confirm',
      shouldShowCloseIcon: false,
      errorAction: CustomActionsEnum.UpdatePaymentMethods,
    },
  ],
  [
    ErrorCodes.CheckOutMissingPaymentTokenErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.paymentTokenNotAvailable.title',
      errorDescription: 'checkout.paymentTokenNotAvailable.description',
      confirmText: 'checkout.paymentTokenNotAvailable.confirm',
      shouldShowCloseIcon: false,
      errorAction: CustomActionsEnum.UpdatePaymentMethods,
    },
  ],
  [
    ErrorCodes.CheckOutOnlyScheduledOrdersAvailableErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.onlyScheduledOrdersAvailable.title',
      errorDescription: 'checkout.onlyScheduledOrdersAvailable.description',
      confirmText: 'checkout.onlyScheduledOrdersAvailable.confirm',
      onConfirm: () => changeRoute(URLS.ESTIMATED_TIME),
      shouldShowCloseIcon: true,
      errorAction: CustomActionsEnum.RefetchStoreSettingsAndCartItems,
    },
  ],
  [
    ErrorCodes.CheckOutOnlyASAPOrdersAvailableErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.onlyASAPOrdersAvailable.title',
      errorDescription: 'checkout.onlyASAPOrdersAvailable.description',
      confirmText: 'checkout.onlyASAPOrdersAvailable.confirm',
      shouldShowCloseIcon: false,
      errorAction: CustomActionsEnum.RefetchStoreSettingsAndCartItems,
    },
  ],
  [
    ErrorCodes.CheckOutOrderScheduledAfterAllowedErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.orderScheduledAfterAllowed.title',
      errorDescription: 'checkout.orderScheduledAfterAllowed.description',
      confirmText: 'checkout.orderScheduledAfterAllowed.confirm',
      onConfirm: () => changeRoute(URLS.ESTIMATED_TIME),
      shouldShowCloseIcon: true,
      errorAction: CustomActionsEnum.RefetchModeDetails,
    },
  ],
  [
    ErrorCodes.CheckOutOrderScheduledBeforeAllowedErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.orderScheduledBeforeAllowed.title',
      errorDescription: 'checkout.orderScheduledBeforeAllowed.description',
      confirmText: 'checkout.orderScheduledBeforeAllowed.confirm',
      onConfirm: () => changeRoute(URLS.ESTIMATED_TIME),
      shouldShowCloseIcon: true,
      errorAction: CustomActionsEnum.RefetchModeDetails,
    },
  ],
  [
    ErrorCodes.CheckOutVoucherCodeInvalidErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.voucher.invalidCode.title',
      errorDescription: 'checkout.voucher.invalidCode.description',
      confirmText: 'checkout.voucher.invalidCode.confirm',
      shouldShowCloseIcon: false,
      onConfirmCustomAction: CustomActionsEnum.RemoveVoucher,
    },
  ],
  [
    ErrorCodes.CheckOutVoucherCodeNotValidForOrderTypeErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.voucher.notValidForOrderType.title',
      errorDescription: 'checkout.voucher.notValidForOrderType.description',
      confirmText: 'checkout.voucher.notValidForOrderType.confirm',
      shouldShowCloseIcon: false,
      onConfirmCustomAction: CustomActionsEnum.RemoveVoucher,
    },
  ],
  [
    ErrorCodes.CheckOutNotEligibleVoucherItems,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.voucher.notValidForProduct.title',
      errorDescription: 'checkout.voucher.notValidForProduct.description',
      confirmText: 'checkout.voucher.notValidForProduct.confirm',
      shouldShowCloseIcon: false,
      onConfirmCustomAction: CustomActionsEnum.RemoveVoucher,
    },
  ],
  [
    ErrorCodes.CheckOutInvalidHappyHourVoucher,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.voucher.notValidForScheduled.title',
      errorDescription: 'checkout.voucher.notValidForScheduled.description',
      confirmText: 'checkout.voucher.notValidForScheduled.confirm',
      shouldShowCloseIcon: false,
      onConfirmCustomAction: CustomActionsEnum.RemoveVoucher,
    },
  ],
  [
    ErrorCodes.CheckOutVoucherCodeExceedsRedemptionErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.voucher.codeExceedsRedemption.title',
      errorDescription: 'checkout.voucher.codeExceedsRedemption.description',
      confirmText: 'checkout.voucher.codeExceedsRedemption.confirm',
      shouldShowCloseIcon: false,
      onConfirmCustomAction: CustomActionsEnum.RemoveVoucher,
    },
  ],
  [
    ErrorCodes.CheckOutVoucherCodeMinimumAmountNotMetErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.voucher.minimumAmountNotMet.title',
      errorDescription: 'checkout.voucher.minimumAmountNotMet.description',
      confirmText: 'checkout.voucher.minimumAmountNotMet.confirm',
      shouldShowCloseIcon: false,
      onConfirmCustomAction: CustomActionsEnum.RemoveVoucher,
    },
  ],
  [
    ErrorCodes.CheckOutDailyCapacityInsufficientErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'cartItem.itemUnavailable.capacity.description',
      errorAction: CustomActionsEnum.MutateCartDetails,
    },
  ],
  [
    ErrorCodes.CheckOutDailyCapacityTransactionErrorCode,
    {
      errorType: ErrorHandlers.SnackBar,
      errorDescription: 'cartItem.itemUnavailable.capacity.description',
      errorAction: CustomActionsEnum.MutateCartDetails,
    },
  ],
  [
    ErrorCodes.CheckOutInvalidTimeSlotErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.invalidSlotTime.title',
      errorDescription: 'checkout.invalidSlotTime.description',
      confirmText: 'checkout.invalidSlotTime.confirm',
      onConfirm: () => changeRoute(URLS.ESTIMATED_TIME),
      shouldShowCloseIcon: true,
    },
  ],
  [
    ErrorCodes.CheckOutInvalidDeliveryZonesSystemAddressErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'checkout.invalidDeliverZonesSystemAddress.title',
      errorDescription: 'checkout.invalidDeliverZonesSystemAddress.description',
      confirmText: 'checkout.invalidDeliverZonesSystemAddress.confirm',
      onConfirmCustomAction: CustomActionsEnum.RedirectToEditAddress,
      shouldShowCloseIcon: true,
    },
  ],
  [
    ErrorCodes.CartArchivedErrorCode,
    {
      errorType: ErrorHandlers.onErrorAction,
      errorAction: CustomActionsEnum.RemoveCartId,
      errorDescription: 'checkout.cartArchived.description',
    },
  ],
  [
    ErrorCodes.CartNotFound,
    {
      errorType: ErrorHandlers.onErrorAction,
      errorAction: CustomActionsEnum.RemoveCartId,
    },
  ],
  [
    ErrorCodes.CartLinkErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'cartLinkFailed.title',
      errorDescription: 'cartLinkFailed.description',
      confirmText: 'cartLinkFailed.confirm',
      shouldShowCloseIcon: true,
    },
  ],
  [
    ErrorCodes.CartExpiredErrorCode,
    {
      errorType: ErrorHandlers.Modal,
      errorTitle: 'cartLinkExpired.title',
      errorDescription: 'cartLinkExpired.description',
      confirmText: 'cartLinkExpired.confirm',
      errorAction: CustomActionsEnum.RemoveCartId,
    },
  ],
]);

export default basicErrorsSchema;
